/* global DEVMODE, TimelineMax, TweenLite, Power3 */

import FastClick from 'modern-fastclick';

import { newSniff } from './modules/sniffer.js';
import Header from './parts/header.js';
import Footer from './parts/footer.js';
import Routes from './modules/routes.js';
import { leaves } from './modules/leaves.js';

window.DEVMODE = DEVMODE === 'development';
window.Sniff;

class App {

  constructor() {

    // Declarations
    this.sy = 0;
    this.sniffer;
    this.DOM = {};
    this.DOM.Body = document.body;
    this.DOM.App = this.DOM.Body.querySelector('.App');
    this.DOM.Header = this.DOM.Body.querySelector('.Header');
    this.DOM.Footer = this.DOM.Body.querySelector('.Footer');
    this.DOM.Loader = this.DOM.Body.querySelector('.Loader');
    this.DOM.Outdated = this.DOM.Body.querySelector('#Outdated');

    // Signature Wokine
    console.log('%cCreated by Wokine, with ❤', 'color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;');
    console.log('%c⚡ http://wokine.com ⚡', 'color:#ccc');

    this.init();
    // this.addEvents();

  }

  init() {

    // DevMode
    if (window.DEVMODE) console.log('🔥 DEVMODE BIATCH 🔥');

    // Sniffer
    window.Sniff = newSniff();

    // OutdatedBrowser
    if (window.Sniff.browser.name === 'ie' && window.Sniff.browser.majorVersion <= 10) {
      console.log('<= ie010');
      this.DOM.Body.classList.remove('is--first');
      this.DOM.Body.classList.remove('is--loading');
      this.DOM.Outdated.classList.add('show');
      return;
    }

    // Chrome Scroll Manual
    this.DOM.Body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    // Create Header/Footer/Routes
    this.Header = new Header(this.DOM.Header);
    this.Footer = new Footer(this.DOM.Footer);
    this.Routes = new Routes(this.Header, leaves);

    window.leaves = new p5(leaves);


    // DOMContentLoaded
    document.addEventListener('DOMContentLoaded', () => {

      // Add Fast Click
      new FastClick(document.body);
      // Reset Scroll
      window.scrollTo(0, 0);
      // Intro
      this.intro();

    }, false);

  }

  addEvents() {
    window.addEventListener('scroll', () => {
      this.sy = window.pageYOffset || document.documentElement.scrollTop;
    });
  }

  intro() {

    if(window.leaves.loaded == false) {

       window.setTimeout(() => {
         this.intro()
       }, 100);

    } else {

      this.DOM.Body.classList.remove('is--first');
      this.DOM.Body.classList.remove('is--loading');

      TweenLite.fromTo(document.querySelector('#leaves .leaf'), 1.6, {
          y: window.innerHeight * -0.1,
          x: 20,
          rotation:-60,
          opacity:0
      }, {
        y: 0,
        x: 0,
        rotation: 0,
        opacity:1,
        ease:Power4.easeOut,
        onComplete : () => {
          window.leaves.leaveLeafs(false);
          TweenLite.fromTo(document.querySelector('.App > *'), 2.2, { y: 200 }, { y: 0, ease: Power4.easeInOut, clearProps: 'all' });
          TweenLite.fromTo(document.querySelector('.Logo__leaf'), 1.4, { y: -40, x:10, rotation:-60, opacity:0 }, { y: 0, x:0, opacity:1, rotation:0, ease: Power4.easeOut, clearProps: 'all', delay:1.6 });
        }
      });
    }
  }
}

new App();
