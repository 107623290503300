import Highway from '@dogstudio/highway';
import CustomPlayer from '../modules/video'

export default class PageSF extends Highway.Renderer {

  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.videoPlay = this.DOM.view.querySelector('.video__container')
    this.DOM.video = this.DOM.view.querySelector('.video__popup video')

    this.init()

  }

  init() {
    
    const { videoPlay, video } = this.DOM

    if (video) this.player = new CustomPlayer(videoPlay, video)

  }

  onLeaveCompleted() {

    if (this.player) this.player.destroy()

  }


}
