export default class Leaf {

  constructor(x, y, rotation, width, shape) {

    if (window.DEVMODE) console.log(`New Leaf`);

    this._x = x;
    this._y = y;
    this.currentX = this._x;
    this.currentY = this._y;
    this.currentScale = 1;
    this.currentRotate = rotation;
    this.shape = shape;
    this.ratio = this.shape.width / this.shape.height;
    this.width = width;

  }

  show(p) {

    p.push();
    p.translate(this.currentX, this.currentY);
    p.rotate(p.sin(this.currentRotate));
    p.scale(this.currentScale);
    p.image(this.shape, 0, 0, this.width, this.width / this.ratio);
    p.pop();

  }
}
