import Highway from '@dogstudio/highway';

export default class PageCarte extends Highway.Renderer {

  onEnter() {

    console.log('PageCarte');
    this.DOM = { view: this.wrap.lastElementChild };

    // Scroll into view anchor
    if (window.location.hash.substr(1).length > 0) {
      setTimeout(function(){
        var element = document.getElementById(window.location.hash.substr(1).split("="));
        element.scrollIntoView();
       }, 1000);
    }

    // WITH VARIATION
    $("select#variation").prop('required',true);

    $(".variations_form").on('submit',function(e){
        e.preventDefault();
        var form = $(this);

        $.ajax( {
          type: "POST",
          url: form.attr( 'action' ),
          data: form.serialize(),
          success: ( data )=> {
            window.cart_nb_item.innerText = parseInt(window.cart_nb_item.innerText) + 1;
            $(this).children('.result').fadeToggle();
            setTimeout( ()=> {
              $(this).children('.result').fadeToggle();
            }, 700);
          },
          error : function(data){
          }
        });
    });

    $("form select#variation").on("change",function(){
      var self = $(this);
      var product_id = self.parents("form.variations_form").attr('data-product_id');
      var attribute = self.val();

      $(".variable_price_" + product_id).hide();
      if (attribute.length > 0) {
        $(".variable_price_" + product_id+"[data-attribute='"+ attribute +"']").show();
      }else {
        $(".variable_price_" + product_id+"[data-attribute='none']").show();
      }
    })

    // PRODUCT SIMPLE
    // $(".add_product_simple").on('click',function(e){
    //   e.preventDefault();
    //   var self = $(this);
    //   var ajax_url = self.attr("data-ajaxurl");
    //
    //   $.ajax({
    //     type: 'GET',
    //     url: ajax_url,
    //     success: function(data) {
    //       var result = self.siblings("span.result");
    //       result.show();
    //       setTimeout(()=>{
    //         result.fadeToggle();
    //       }, 700);
    //     }
    //   });
    // })
      //
        // PRODUCT SIMPLE
        $(".add_product_simple").on('click',function(e){
          e.preventDefault();
          var self = $(this);
          var add_product_ajaxurl = self.data("add_product_ajaxurl");
          var product_simple_choices = self.data("product_simple_choices");

          if (product_simple_choices) {
            var form = self.siblings(".product_simple_choices").first();
            var data_form = form.serialize()
            add_product_ajaxurl = add_product_ajaxurl  +"&product_simple_choices=" + encodeURIComponent(data_form);
            console.log(add_product_ajaxurl);
          }
          $.ajax({
            type: 'GET',
            url: add_product_ajaxurl,
            success: (data)=> {
              form.trigger('reset');
              window.cart_nb_item.innerText = parseInt(window.cart_nb_item.innerText) + 1;
              self.siblings("span.result").fadeToggle();
              setTimeout( ()=> {
                self.siblings("span.result").fadeToggle();
              }, 700);
            },
          });
        })
      }


}
