import Leaf from './leaf.js';

export const leaves = (p) => {

  let shapes = [];
  let toggleMouse = false;
  var canvas;
  p.leafs = [];
  p.loaded = false;

  let count = 16;

  p.preload = () => {

    const leaf_1 = p.loadImage(`${themeURL}/dist/imgs/SVG/leaf_1.svg`);
    shapes.push(leaf_1);
    const leaf_2 = p.loadImage(`${themeURL}/dist/imgs/SVG/leaf_2.svg`);
    shapes.push(leaf_2);
    const leaf_3 = p.loadImage(`${themeURL}/dist/imgs/SVG/leaf_3.svg`);
    shapes.push(leaf_3);
    const leaf_4 = p.loadImage(`${themeURL}/dist/imgs/SVG/leaf_4.svg`);
    shapes.push(leaf_4);
  };

  p.setup = () => {
    p.loaded = true;
    canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    canvas.parent('leaves');
    p.noLoop();
    p.createLeafs(count);
  };

  p.draw = () => {
    p.clear();
    for (let i = 0; i < p.leafs.length; i++) {
      p.leafs[i].show(p);
    }
  };

  /*p.mousePressed = () => {

    canvas.canvas.parentNode.classList.toggle('is--active');
    if (toggleMouse) p.enterLeafs();
    else p.leaveLeafs();

    toggleMouse = !toggleMouse;
  }*/

  p.createLeafs = (c) => {
    p.leafs = [];
    for (let i = -1; i <= c; i++) {
      for (let j = -1; j <= c; j++) {

        let randomWidth;
        if (p.windowWidth <= 768) randomWidth = p.random(20,200);
        else randomWidth = p.random(50,300);

        p.leafs.push(new Leaf(i * (p.windowWidth/c), j * (p.windowHeight/c), p.random(-1, 1), randomWidth, p.random(shapes)));
      };
    };
  }

  p.updateLeafs = (c) => {
    let h = 0;
    for (let i = -1; i < c; i++) {
      for (let j = -1; j < c; j++) {
        p.leafs[h]._x = i * (p.windowWidth/c);
        p.leafs[h]._y = j * (p.windowHeight/c);
        h++;
      };
    };
  }

  p.windowResized = () => {
  	p.resizeCanvas(p.windowWidth, p.windowHeight);
    p.updateLeafs(count);
  };

  p.leaveLeafs = (done) => {

    if (window.DEVMODE) console.log('leaveLeafs');

    p.loop();

    TweenLite.to(canvas.canvas.parentNode.querySelector('.leaf'), 1, {
      y: -p.windowHeight * 0.3,
      x: p.windowWidth * 0.1,
      rotation: 20,
      opacity:0,
      ease:Power4.easeIn,
      delay: 0
    });

    TweenLite.to(canvas.canvas.parentNode, 0.8, {
      backgroundColor: "rgba(248,167,93,0)",
      ease:Power4.easeIn,
      delay: 0.4
    });

    const shuffleLeafs = p.shuffle(p.leafs);
    for (let i = 0; i < shuffleLeafs.length; i++) {

      TweenLite.to(shuffleLeafs[i], 1.4, {
        currentX: shuffleLeafs[i]._x + p.windowWidth * 2,
        currentY: p.random(p.windowHeight * 2, p.windowHeight * 3) * -1,
        currentRotate : p.random(1, 2),
        currentScale:0.1,
        ease:Power4.easeIn,
        onComplete : () => {

          if (i == shuffleLeafs.length - 1) {
            if(done) done();
            p.noLoop();
            document.body.classList.remove('is--animating');
          }

        },
        delay:0.002 * i
      });

    }

  }

  p.enterLeafs = (done) => {

    if (window.DEVMODE) console.log('enterLeafs');

    p.loop();

    TweenLite.fromTo(canvas.canvas.parentNode.querySelector('.leaf'), 1.6, {
      y: p.windowHeight * -0.3,
      x: p.windowWidth * -0.1,
      rotation:-60,
      opacity:0
    }, {
      y: 0,
      x: 0,
      rotation: 0,
      opacity:1,
      ease:Power4.easeOut,
      onComplete : () => {
        if(done) done();
        p.noLoop();
      },
      delay:0.6
    });

    TweenLite.to(canvas.canvas.parentNode, 0.8, {
      backgroundColor: "rgba(248,167,93,1)",
      ease:Power4.easeOut,
      delay:0.8
    });

    const shuffleLeafs = p.shuffle(p.leafs);
    for (let i = 0; i < shuffleLeafs.length; i++) {

      TweenLite.fromTo(shuffleLeafs[i], 1.2,
        {
          currentRotate : p.random(-2, 0),
          currentX: p.random(p.windowWidth * 1, p.windowWidth * 2) * -1,
          currentY: p.random(p.windowHeight * 2, p.windowHeight * 3) * -1,
          currentScale:0.2,
        },
        {
          currentRotate : p.random(-1, 1),
          currentX:shuffleLeafs[i]._x,
          currentY:shuffleLeafs[i]._y,
          currentScale:1,
          ease:Power4.easeOut,
          delay:0.001 * p.random(shuffleLeafs.length)
        }

      );
    }
  }
}
