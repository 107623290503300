import Highway from '@dogstudio/highway';

export default class PageFindUs extends Highway.Renderer {

  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.header = document.querySelector('.Header');
    this.DOM.boutiquesSwiper = this.DOM.view.querySelector('.Boutiques__swiper');
    this.DOM.gallerySwiper = this.DOM.view.querySelector('.gallery__swiper .swiper-container')

    this.DOM.header.classList.add('is--dark');

    if (this.DOM.boutiquesSwiper) this.initBoutiques();

    if (this.DOM.gallerySwiper) this.initGallerySwiper()

  
  }

  initBoutiques() {

    this.boutiquesSwiper = new Swiper(this.DOM.boutiquesSwiper, {
      slidesPerView: 3,
      spaceBetween: 16,
      breakpoints: {
        540: { slidesPerView: 1 },
        992: { slidesPerView: 2 }
      }
    });

  }

  initGallerySwiper() {

    const { gallerySwiper } = this.DOM

    this.gallerySwiper = new Swiper(gallerySwiper, {
      slidesPerView: 1,
      navigation: {
        nextEl: this.DOM.view.querySelector('.gallery__swiper .next'),
        prevEl: this.DOM.view.querySelector('.gallery__swiper .prev'),
      }
    })

  }

  onLeaveCompleted() {
    this.DOM.header.classList.remove('is--dark');
    if (this.boutiquesSwiper) this.boutiquesSwiper.destroy();
    if (this.gallerySwiper) this.gallerySwiper.destroy()
  }

}
