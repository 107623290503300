/* global TweenLite, TimelineLite, Power3 */

import { clamp, normalize } from '../utils.js';

export default class pagePrllx {

  constructor(container) {

    this.DOM = {
      elems: container.querySelectorAll('[data-prllxfrom]')
    };

    if (this.DOM.elems.length === 0) return;

    this.state = {
      scroll: {
        current: 0
      }
    };

    this.elems = null;
    this.onScroll = this.onScroll.bind(this);

    this.init();
    this.addListeners();

  }

  init() {

    if (window.DEVMODE) console.log('Init prllx');

    this.elems = [];

    // Create PrllxElement
    this.DOM.elems.forEach((el) => {

      const tl = new TimelineLite({ paused: true });
      const from = JSON.parse(el.dataset.prllxfrom);
      const to = JSON.parse(el.dataset.prllxto);
      tl.ease = Power0.linear;

      tl.fromTo(el, 1, from, to);

      this.elems.push({
        el: el,
        tl: tl,
        threshold: typeof el.dataset.prllxratio === 'undefined' ? 1 : el.dataset.prllxratio,
        progress: {
          current: 0
        }
      });

    });

  }

  onScroll() {
    this.state.scroll.current = window.pageYOffset || document.documentElement.scrollTop;
  }

  onRender() {

    this.elems.forEach(({el, tl, progress, threshold}) => {

      const { current } = this.state.scroll;
      const elTop = el.getBoundingClientRect().top;
      const elHeight = el.offsetHeight;

      const start = elTop + current - window.innerHeight <= 0 ? 0 : elTop + current - window.innerHeight;
      const end = elTop + current + elHeight;
      const isVisible = start < current && end >= current;

      if (isVisible) {
        progress.current = clamp(normalize(current, start, end), 0, threshold);
        tl.progress(progress.current);
      }

    });

  }

  addListeners() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    TweenLite.ticker.addEventListener('tick', this.onRender, this, true, 1);
  }

  destroy() {
    if (window.DEVMODE) console.log('Destroy prllx');
    window.removeEventListener('scroll', this.onScroll);
    TweenLite.ticker.removeEventListener('tick', this.onRender);
  }

}
