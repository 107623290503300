
export default class CustomPlayer {
  constructor(videoPlay, video){

    this.videoPlay = videoPlay
    this.video = video
    

    this.init()
  }

  init(){
    const { videoPlay, video } = this

    this.playVideo = this.playVideo.bind(this)
    this.clickOutside = this.clickOutside.bind(this)

    if (video) videoPlay.addEventListener('click', this.playVideo)
  }

  playVideo(e) {
    const { video } = this

    e.preventDefault()

    console.log('test')

    video.play()
    document.body.classList.add('showVideo')
    setTimeout(() => document.addEventListener('click', this.clickOutside), 1000)
  }

  stopVideo() {
    const { video } = this

    document.body.classList.remove('showVideo')
    document.removeEventListener('click', this.clickOutside)
    setTimeout(() => {
      video.pause()
      video.currentTime = 0
      video.load()
    }, 500)
  }

  clickOutside(e) {
    const { video } = this
    const isClickedInside = video.contains(e.target)

    if (!isClickedInside) this.stopVideo()
  }


  destroy(){
    document.body.classList.remove('showVideo')
  }
}