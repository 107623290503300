// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import SingleEpicerie from '../pages/single-epicerie.js';
import PageHome from '../pages/page-home.js';
import PageFindUs from '../pages/page-findus.js';
import PageCarte from '../pages/page-carte.js';
import PageCreation from '../pages/page-creation.js';
import PageVoyages from '../pages/page-voyages.js';
import PageEpicerie from '../pages/page-epicerie.js';
import PageSF from '../pages/page-sf.js';
import SingleBoutique from '../pages/single-boutique.js';
import DefaultPageTransition from '../pages/page-transition.js';


import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageScroll from './scroll.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

  }

  createHighway() {

    window.H = new Highway.Core({
      renderers: {
        pagename: DefaultPageRenderer,
        pageHome: PageHome,
        singleEpicerie: SingleEpicerie,
        pageFindUs: PageFindUs,
        pageCarte: PageCarte,
        pageEpicerie: PageEpicerie,
        pageCreation: PageCreation,
        singleBoutique: SingleBoutique,
        pageVoyages: PageVoyages,
        pageSF: PageSF

      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // RemovePrllx
      this.view.prllx.destroy();

    });

    window.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is--active');
        if (link.href === location.href) {
          link.classList.add('is--active');
        }
      });

      this.initView(to.view);

    });

    window.H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      // Analytics
      if (typeof gtag !== 'undefined' && !window.DEVMODE) {
         gtag('config', 'UA-154895375-1', {
           'page_path': location.pathname,
           'page_title': to.page.title,
           'page_location': location.href
         });
      }

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Animations
    this.view.scroll = new pageScroll(container);
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);

  }

}
