/* global TimelineMax, Power3 */
import Highway from '@dogstudio/highway';

export default class DefaultPageTransition extends Highway.Transition {

  in({ from, to, trigger, done }) {

    // Remove Last Page
    if (window.DEVMODE) console.log('Leaving Page : ', from);

    document.body.classList.remove('is--loading');
    from.parentNode.removeChild(from);

    document.body.classList.remove('showMenu');

    TweenLite.fromTo(to, 2.2, { y: 200 }, { y: 0, ease: Power3.easeInOut, clearProps: 'all' });
    TweenLite.fromTo(document.querySelector('.Logo__leaf'), 1.4, { y: -40, x:10, rotation:-60, opacity:0 }, { y: 0, x:0, opacity:1, rotation:0, ease: Power4.easeOut, clearProps: 'all', delay:1.6 });
    window.leaves.leaveLeafs(done);

    //done();

  }

  out({ from, trigger, done }) {

    // Timeline animation page leave

    document.body.classList.add('is--loading');
    document.body.classList.add('is--animating');
    window.leaves.enterLeafs(done);

  }

}
