export default class Header {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.body = document.body;
    this.DOM.brand = this.DOM.el.querySelector('.brand');
    this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');

    this.DOM.overlay = document.querySelector('#Overlay');

    this.state = {
      currentScroll : 0,
      isMin : false,
      menuOpen : false
    }

    window.cart_nb_item = document.getElementById('cart_nb_item');

    this.init();

    this.onScroll = this.onScroll.bind(this);
    window.addEventListener('scroll', this.onScroll, { capture: false, passive: true});

  }

  init() {

    if (window.DEVMODE) console.log('initHeader');

    // Toggle
    this.DOM.toggle.addEventListener('click', (e) => {

      e.preventDefault();

      e.currentTarget.classList.toggle('is--active');

      const { menuOpen } = this.state;
      menuOpen === true ? this.close() : this.open();

    });

    // Overlay
    this.close = this.close.bind(this)
    this.DOM.overlay.addEventListener('click', this.close)

    $("#form_newsletter").on("submit", function(e) {
      e.preventDefault();
      var self = $(this);
      $('body').css( 'cursor', 'wait');
      var ajaxurl = $(this).data("ajaxurl");
      var email = $("#NewsletterFooterInscription").val();

      // Back office
      $.ajax({
        type: "POST",
        url: ajaxurl,
        data: self.serialize(),
        success: function(data) {
          $('body').css( 'cursor', 'default');
          var data = $.parseJSON(data);
          if (data.status == true){
            self.trigger('reset');
            $(".output_newsletter").show().text(data.message).css("color","white")
          } else {
            $(".output_newsletter").show().text(data.message).css("color","#e65a5a")

          }
        },
        error: function(data) {
          $('body').css( 'cursor', 'default');
          var data = $.parseJSON(data);
          console.log(data);
          $(".error_message").show();
        }
      });
    })
  }

  onScroll() {

    this.state.currentScroll = window.pageYOffset || document.documentElement.scrollTop;

    const { currentScroll } = this.state;
    const { isMin } = this.state;

     if (currentScroll > 100 && !isMin) {
       this.state.isMin = true;
       this.DOM.el.classList.add('is--min');
     }

     if (currentScroll <= 100 && isMin) {
       this.state.isMin = false;
       this.DOM.el.classList.remove('is--min');
     }
  }

  open() {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Open Menu');
      this.state.menuOpen = true;
      this.DOM.body.classList.add('showMenu');

    });

  }

  close() {

    return new Promise((resolve, reject) => {

      if (window.DEVMODE) console.log('Close Menu');
      this.state.menuOpen = false;
      this.DOM.body.classList.remove('showMenu');

    });

  }

}
