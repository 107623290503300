import Highway from '@dogstudio/highway';

export default class PageEpicerie extends Highway.Renderer {

  onEnter() {

    console.log('PageEpicerie');
    this.DOM = { view: this.wrap.lastElementChild };

    // PRODUCT SIMPLE
    $(".add_product_simple").on('click',function(e){
      e.preventDefault();
      var self = $(this);
      var ajax_url = self.attr("data-add_product_ajaxurl");
      $.ajax({
        type: 'GET',
        url: ajax_url,
        success: (data)=> {
          var result = self.siblings("span.result");
          result.show();
          window.cart_nb_item.innerText = parseInt(window.cart_nb_item.innerText) + 1;
          console.log("test");
          setTimeout(function(){
            result.fadeOut(500);
          }, 400);
        }
      });
    })


  }
}
