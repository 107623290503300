import Highway from '@dogstudio/highway';

export default class PageCreation extends Highway.Renderer {

  onEnter() {
    console.log('PageCreation');
    this.DOM = { view: this.wrap.lastElementChild };

    this.plyr = new Plyr('#plyr');
  }

}
