export default class Footer {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.App = document.body.querySelector('.App');

    this.init();
    this.addEvents();

    if (window.innerWidth > 768) {
      this.updateFooterSize();
    }

  }

  init() {

    if (window.DEVMODE) console.log('init Footer');

  }

  updateFooterSize() {

    this.DOM.App.style.paddingBottom = `${this.DOM.el.offsetHeight}px`;

  }

  addEvents() {

    let resizeEvent = false;
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        clearTimeout(resizeEvent);
        resizeEvent = setTimeout(this.updateFooterSize(), 500);
      }
    });

  }

}
