import Highway from '@dogstudio/highway';
import CustomPlayer from '../modules/video'

export default class PageVoyages extends Highway.Renderer {

  onEnter() {

    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.voyagesSwiper = this.DOM.view.querySelectorAll('.Voyages__swiper')
    this.DOM.videoPlay = this.DOM.view.querySelector('.video__container')
    this.DOM.video = this.DOM.view.querySelector('.video__popup video')

    this.init()

  }

  init() {
    // Scroll into view anchor
    if (window.location.hash.substr(1).length > 0) {
      setTimeout(function () {
        var element = document.getElementById(window.location.hash.substr(1).split("="));
        element.scrollIntoView();
      }, 1000);
    }

    const { voyagesSwiper, videoPlay, video } = this.DOM

    if (voyagesSwiper.length > 0) this.initVoyagesSwiper()
    if (video) this.player = new CustomPlayer(videoPlay, video)


  }

  initVoyagesSwiper() {

    const { voyagesSwiper } = this.DOM

    this.swipers = []

    voyagesSwiper.forEach(voyage => {
      const swiper = new Swiper(voyage.querySelector('.swiper-container'), {
        slidesPerView: 1,
        navigation: {
          nextEl: voyage.querySelector('.next'),
          prevEl: voyage.querySelector('.prev')
        }
      })
      
      this.swipers.push(swiper)
    })

  }

  onLeaveCompleted() {

    const { swipers } = this

    if (swipers.length > 0) swipers.forEach(swiper => swiper.destroy())

    if (this.player) this.player.destroy()

  }


}
