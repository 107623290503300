import { lerp } from '../utils.js';
import Highway from '@dogstudio/highway';

export default class SingleEpicerie extends Highway.Renderer {

  onEnter() {

    console.log('SingleEpicerie');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.visual = this.DOM.view.querySelector('#ProductVisual');
    this.DOM.associationContainer = this.DOM.view.querySelector('.Associations');
    this.DOM.associationSwiper = this.DOM.view.querySelector('.Associations__swiper');
    this.DOM.relatedSwiper = this.DOM.view.querySelector('.Related__swiper');

    if (this.DOM.visual) {
      this.state = {
        scroll: {
          current: 0,
          target : 0
        }
      };
      this.onScroll = this.onScroll.bind(this);
      this.addListeners();
    }

    if (this.DOM.relatedSwiper) this.initRelated();

    $("input[name='quantity']").hide();

    // PRODUCT SIMPLE
    $("form.cart").on('submit',function(e){
        e.preventDefault();
        var form = $(this);
        // console.log(form.serialize());

        $.ajax( {
          type: "POST",
          url: form.attr( 'action' ),
          data: form.serialize(),
          success: function( response ) {
            var result = $(".result");
            window.cart_nb_item.innerText = parseInt(window.cart_nb_item.innerText) + 1;
            result.show();
            setTimeout(function(){
              result.fadeOut(500);
            }, 400);
          }
        });
    });


  }

  onRender() {
    const { scroll } = this.state;
    scroll.target = scroll.current * 0.5;
    TweenLite.set(this.DOM.visual, {y: -scroll.target});
  }

  onScroll() {
    this.state.scroll.current = window.pageYOffset || document.documentElement.scrollTop;
  }

  addListeners() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    TweenLite.ticker.addEventListener('tick', this.onRender, this, true, 1);
  }

  initRelated() {

    this.relatedSwiper = new Swiper(this.DOM.relatedSwiper, {
      slidesPerView: 4,
      breakpoints: {
        540: { slidesPerView: 1 },
        768: { slidesPerView: 2 },
        992: { slidesPerView: 3 }
      },
      navigation: {
        nextEl: this.DOM.relatedSwiper.querySelector('.next'),
        prevEl: this.DOM.relatedSwiper.querySelector('.prev')
      }
    });

  }

  onLeaveCompleted() {
    if (this.DOM.visual) {
      window.removeEventListener('scroll', this.onScroll);
      TweenLite.ticker.removeEventListener('tick', this.onRender);
    }
  }

}
